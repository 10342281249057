import React from 'react';
import logoEit from '../../img/logo-espacoit.svg';
import iconFb from '../../img/icon-fb.svg';
import iconWa from '../../img/icon-wa.svg';
import './logo.css';

const Logo = () => {
    return (
        <header className='logo-section'>
            <img className='logo-img' src={logoEit} alt='logo'/>
            <div className='social-container'>
                <a href='https://facebook.com/espacoitbc' target='_blank' rel="noopener noreferrer" className="social-link">
                    <img className='social-img' src={iconFb} alt='facebook' />
                </a>
                <a href='https://wpp.chat/eitsite' target='_blank' rel="noopener noreferrer" className="social-link">
                    <img className='social-img' src={iconWa} alt='whatsapp' />
                </a>
            </div>
        </header>
    );
}

export default Logo;