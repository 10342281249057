import React from 'react';
import './contact.css';
import Title from "../../Components/Title/title";
import Form from '../../Components/Form/form';

const Contact = () => {
    return (
        <div className='contact-section'>
            <Title right>Entre em <strong>contato</strong></Title>
            <Form/>
        </div>
    );
}

export default Contact;