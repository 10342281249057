import React from "react";
import Img from 'react-cool-img';
import { SRLWrapper } from "simple-react-lightbox";
import photoCowork from '../../img/photos/coworking-1.jpg';
import photoFlex1 from '../../img/photos/flex-1.jpg';
import photoFlex3 from '../../img/photos/flex-3.jpg';
import photoFlex4 from '../../img/photos/flex-4.jpg';
import photoAud1 from '../../img/photos/auditorio-1.jpg';
import photoAud2 from '../../img/photos/auditorio-2.jpg';
import photoAud3 from '../../img/photos/auditorio-3.jpg';
import photoAud4 from '../../img/photos/auditorio-4.jpg';
import photoReu1 from '../../img/photos/reuniao-1.jpg';
import photoReu2 from '../../img/photos/reuniao-2.jpg';
import photoReu3 from '../../img/photos/reuniao-3.jpg';
import photoReu4 from '../../img/photos/reuniao-4.jpg';
import './gallery.css';

const Gallery = () => {
    return (
        <SRLWrapper>
            <div className='gallery'>
                <div className='gallery-main-photo'>
                    <p>Flex</p>
                    <Img src={photoFlex1} alt='Flex' />
                </div>
                <Img className='--lightbox-only' src={photoFlex3} width='1920' height='1440' alt='Com capacidade de até 15 lugares, a sala leva este nome por ser flexível à vários tipos de uso.' />
                <Img className='--lightbox-only' src={photoFlex4} width='1920' height='1440' alt='Disponibilidade de carteiras, TV e flipchart.' />
                <div className='gallery-main-photo'>
                    <p>Coworking</p>
                    <Img src={photoCowork} alt='Coworking' />
                </div>
                <div className='gallery-main-photo'>
                    <p>Auditório</p>
                    <Img src={photoAud1} alt='Auditório' />
                </div>
                <Img className='--lightbox-only' src={photoAud3} width='1920' height='1440' alt='O espaço ideal para treinamentos, reuniões e outros tipos de eventos.' />
                <Img className='--lightbox-only' src={photoAud2} width='1920' height='1440' alt='Possui infraestrutura de som e imagem e espaço para "coffee-break".' />
                <Img className='--lightbox-only' src={photoAud4} width='1920' height='1440' alt='Capacidade para 50 pessoas.' />
                <div className='gallery-main-photo'>
                    <p>Reunião</p>
                    <Img src={photoReu1} alt='Reunião'/>
                </div>
                <Img className='--lightbox-only' src={photoReu2} width='1920' height='1440' alt='Um espaço para causar uma ótima impressão ao seu cliente.' />
                <Img className='--lightbox-only' src={photoReu3} width='1920' height='1440' alt='Estruturada para reuniões presenciais ou vídeo-conferências.' />
                <Img className='--lightbox-only' src={photoReu4} width='1920' height='1440' alt='Sala climatizada e com iluminação natural.' />
            </div>
        </SRLWrapper>
    );
}

export default Gallery;