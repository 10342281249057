import React from 'react';
import SimpleReactLightbox from "simple-react-lightbox";
import Logo from './Sections/Logo/logo';
import Headline from "./Sections/Headline/headline";
import Directions from "./Sections/Directions/directions";
import Rooms from "./Sections/Rooms/rooms";
import Contact from "./Sections/Contact/contact";
import WhatsAppButton from "./Components/WhatsAppButton/whatsappbutton";
import './App.css';


function App() {
  return (
    <div className="App">
        <SimpleReactLightbox>
            <WhatsAppButton />
            <Logo />
            <Headline />
            <Rooms />
            <Directions />
            <Contact />
        </SimpleReactLightbox>
    </div>
  );
}

export default App;
