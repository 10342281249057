import React from "react";
import InputMask from "react-input-mask";
import './form.css';

const Form = () => {
    return (
        <form action='https://formcarry.com/s/RU1ULgXqM1' method='Post' className='form'>
            <input className='name' placeholder='nome' name='Nome'/>
            <input className='email' placeholder='email' name='Email'/>
            <textarea className='message' placeholder='assunto' name='Assunto' />
            <InputMask mask="(99)999999999" className='phone' placeholder='telefone' name='Telefone' />
            <button type='submit'>Enviar</button>
        </form>
    )
}

export default Form;