import React from 'react';
import Title from '../../Components/Title/title';
import Gallery from '../../Components/Gallery/gallery';
import './rooms.css';

const Rooms = () => {
    return (
        <div className='rooms-section'>
            <Title right>Conheça nossos <strong>espaços</strong></Title>
            <Gallery/>
        </div>
    );
}

export default Rooms;