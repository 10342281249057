import React from 'react';
import Img from 'react-cool-img';
import mapImg from '../../img/staticmap.jpg';
import './staticmap.css';

const StaticMap = () => {
    return(
      <div className='staticmap'>
          <p className='staticmap-address'>R. Padre Albuquerque, 1111 - Centro<br/>Itapetininga - SP, 18200-075</p>
          <Img className='staticmap-img' src={mapImg} alt=''/>
          <div className='staticmap-buttons' >
              <a
                 href='https://www.google.com/maps/place/Espa%C3%A7o+It/@-23.582922,-48.0391237,17z/data=!3m1!4b1!4m5!3m4!1s0x94c5cc8b772cce9d:0xdcbdf2ce8f65508!8m2!3d-23.582922!4d-48.036935'
                 target='_blank'
                 rel='noopener noreferrer'
              >Ver no Mapa</a>
              <a
                 href='https://www.waze.com/ul?place=ChIJnc4sd4vMxZQRCFX26Czfyw0&ll=-23.58292200%2C-48.03693500&navigate=yes'
                 target='_blank'
                 rel='noopener noreferrer'
              >Abrir no Waze</a>
          </div>
      </div>
    );
}

export default StaticMap;