import React from 'react';
import './headline.css';

const Headline = () => {
    return (
        <div className='headline-section'>
            <h1>
                <span className='sm'>Um <strong>lugar</strong></span> <br />
                <span className='md'>para fazer <strong>bons</strong><br /></span>
                <span className='lg'><strong>Negócios</strong></span>
            </h1>
            <p>Conheça <strong>um local de trabalho diferenciado</strong>!<br /><br />Um ambiente moderno e agradável,
                com a estrutura necessária para você realizar suas tarefas com conforto e comodidade.</p>
        </div>
    );
}

export default Headline;