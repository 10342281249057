import React from 'react';
import Title from '../../Components/Title/title';
import './directions.css';
import StaticMap from "../../Components/StaticMap/staticmap";

const Directions = () => {
    return (
        <div className='directions-section'>
            <Title left><strong>Onde estamos</strong></Title>
            <StaticMap />
        </div>
    );
}

export default Directions;