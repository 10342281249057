import React from "react";
import Img from "react-cool-img";
import waIcon from '../../img/icon-wa-white.svg';
import './whatsappbutton.css';

const WhatsAppButton = () => {
    return (
        <div className='whatsappbutton'>
            <a href='https://wpp.chat/eitsite' target='_blank' rel='noopener noreferrer'>
                <Img src={waIcon} alt=""/>
            </a>
        </div>
    )
}

export default WhatsAppButton;