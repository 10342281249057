import React from 'react';
import './title.css';

const Title  = (props) => {
    return (
        <div className={`title ${props.left ? '--left' : '--right'}`}>
            <h3>{props.children}</h3>
            <div className='title-underline'/>
        </div>
    );
}

export default Title;